.hideCard{
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-name: slideOutCard;
}

@keyframes slideOutCard {
  from {
    max-height: 140px;
    margin-top: 10px;
  }


  to {
    max-height: 0px;
    display: none;
    margin-top: 0px;
    z-index: -2;
  }
}


.editorCard{
  border-radius: 13px;
  box-shadow: none;
  background-color: white;
  padding: 24px;
  margin-top: 20px;

  & input{
    background: white;
    background-color: white;
  }

  &__m2a_select,
  &__m2a_select:hover,
  &__m2a_select:focus,
  &__m2a_select :focus{
    background: white !important;
    background-color: white !important;

  }

  &__m2a_select div.MuiSelect-select{
    padding-top: 12px;
  }

  &__m2alabel{
    top: 14px;
    left: -2px;
  }

}

.modal_sure{
  background-color: white;
  position: absolute;
  border-radius: 13px;
  padding: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;

}




