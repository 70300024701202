html,
body,
#root,
.root{
    min-height: 100vh;
}

.root{
    background: #f4f4f4;
}


a{
    color: #00000066;
    text-decoration: none;
}

