@import './Vars.module.scss';

.AuthContainer{

    &_AuthCard{
        

        &__input input{
            background-color: white;
        }
    }
}